import React from "react";
import { Link } from "gatsby";
import { Layout } from "../components";

const pageReference = ({ pageContext }) => {
	const { title, content, pdf, image, next, prev, header, seo } =
		pageContext.data;
	return (
		<Layout
			seo={{
				...seo,
				href: "pl",
				lang: "pl",
			}}
		>
			<section className="container-fluid reference_single_section_1">
				<div className="text-center">
					<h1>
						<div
							className="inner"
							dangerouslySetInnerHTML={{ __html: title }}
						/>
					</h1>
				</div>
			</section>
			<section className="reference_single_section_2">
				<div className="row">
					<div className="col-md-5">
						<a href={pdf} target="_blank" rel="noopener noreferrer">
							<img
								className="img-fluid"
								src={image}
								alt={title}
							/>
						</a>
					</div>
					<div className="col-md-7">
						<div className="inner">
							{header ? <h2>{header}</h2> : ""}
						</div>
						<div
							className="inner"
							dangerouslySetInnerHTML={{ __html: content }}
						/>
					</div>
				</div>
			</section>
			<section className="container-fluid single_post_navigation">
				<div className="row no-gutters single_post_navigation__wrapper">
					{next ? (
						<div
							className="col-sm-6 single_post_navigation__box"
							key={next.id}
						>
							<Link
								to={`/referencje/${next.slug}`}
								className="inner"
							>
								<p>
									<span
										className="nav-title"
										dangerouslySetInnerHTML={{
											__html: next.title,
										}}
									/>
								</p>
								<div>
									<span className="btn btn-line">
										poprzedni
									</span>
								</div>
							</Link>
						</div>
					) : (
						<div className="col-6 single_post_navigation__box" />
					)}
					{prev ? (
						<div
							className="col-sm-6 single_post_navigation__box"
							key={prev.id}
						>
							<Link
								to={`/referencje/${prev.slug}`}
								className="inner"
							>
								<p>
									<span
										className="nav-title"
										dangerouslySetInnerHTML={{
											__html: prev.title,
										}}
									/>
								</p>
								<div>
									<span className="btn btn-line">
										następny
									</span>
								</div>
							</Link>
						</div>
					) : (
						<div className="col-6 single_post_navigation__box" />
					)}
				</div>
			</section>
		</Layout>
	);
};

export default pageReference;
